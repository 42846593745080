@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
    user-select: none; /* Prevent selection */
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Add this CSS to hide the scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Custom styles go here */
.scale-80 {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.w-80 {
  width: 20rem;
}
.w-90 {
  width: 23rem;
}
.w-100 {
  width: 25rem;
}
.w-120 {
  width: 30rem;
}
.backdrop-blur-3 {
  --tw-backdrop-blur: blur(3px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-6 {
  --tw-backdrop-blur: blur(6px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.mt-30 {
  margin-top: 8rem /* 140px */;
}
.mt-35 {
  margin-top: 9rem /* 150px */;
}
.w-90 {
  width: 24rem /* 340px */;
}
.mt-11 {
  margin-top: 2.76rem /* 45px */;
}

/* Disable zooming on mobile devices */
html, body {
  touch-action: pan-x pan-y; /* Allow both horizontal and vertical scrolling */
  -ms-touch-action: pan-x pan-y; /* Allow both horizontal and vertical scrolling */
}

* {
  touch-action: pan-x pan-y; /* Allow both horizontal and vertical scrolling */
  -ms-touch-action: pan-x pan-y; /* Allow both horizontal and vertical scrolling */
}

* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
